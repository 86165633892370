import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const Page: FC = () => (
  <Layout>
    <SEO
      title="採用のご案内"
      description="獣医師、動物看護師を募集中です。"
      keywords="採用のご案内"
    />
    <article css={pageBaseStyle}>
      <h1>採用のご案内</h1>
      <div css={skewBorder}></div>
      <section>
        <p>
          当院では2024年8月から下記職種の採用を行っております。<br />私たち腫瘍診療チームの一員になり、スキルアップしませんか？
        </p>
      </section>
      <section>
        <h2>採用中の職種</h2>
        <p>
          <ul>
            <li><s>・獣医師</s>　→現在募集しておりません</li>
            <li>・動物看護師</li>
          </ul>
        </p>
      </section>
      <section>
        <h2>愛玩動物看護師（正社員・パートタイマー）</h2>
        <h3>【主な仕事内容】</h3>
        <p>
          <ul>
            <li>・動物看護全般</li>
            <li>・診療補助、入院ケア、手術助手、栄養管理</li>
            <li>・シニアケア</li>
            <li>・受付、電話対応など</li>
          </ul>
        </p>
        <h3>【アピールポイント】</h3>
        <p>
          <ul>
            <li>・腫瘍専門獣医師をはじめ、経験豊富なスタッフが丁寧に教育指導します</li>
            <li>・犬と猫の腫瘍やシニアケアに興味のある方の活躍できる仕事場です</li>
            <li>・病院のとなりは公園と川に囲まれ、落ち着いた環境です</li>
            <li>・清澄白河エリアであり徒歩圏内におしゃれなカフェが沢山あります</li>
          </ul>
          ※院内・院外　全面禁煙
        </p>
        <h3>【応募条件】</h3>
        <p>
          <ul>
            <li>・愛玩動物看護師　国家資格を有し、動物病院での実務経験が3年以上ある方</li>
            <li>・学歴不問</li>
            <li>・髪色、髪型自由</li>
          </ul>
        </p>
        <h3>【勤務時間・曜日】</h3>
        <p>
          &nbsp;&nbsp;〇正社員　9: 00～18: 00（休憩時間 1時間）
          <br />
          &nbsp;&nbsp;〇パートタイマー　勤務時間相談　週2日勤務から可能です
        </p>
        <p>
          <ul>
            <li>・シフト制</li>
            <li>・完全週休2日 祝日休み（日曜午後はシフト制で休み）<span css={emphasis}>→実質週休2.5日</span></li>
            <li>・平均所定労働時間（1か月当たり）: 160時間（正社員）</li>
            <li>・緊急手術や重症患者のケアなどにより残業あり（正社員）</li>
          </ul>
        </p>
        <h3>【休暇・休日】</h3>
        <p>
          <ul>
            <li>・完全週休2日　祝日休み（日曜午後はシフト制で休み）→実質週休2.5日</li>
            <li>・長期休暇取得　可能です</li>
            <li>・産休・育休制度あります</li>
          </ul>
        </p>
        <h3>【給与・待遇・福利厚生】</h3>
        <p>
          &nbsp;&nbsp;〇正社員：月給 ￥230,000以上（経験年数・実績により要相談）
          <br />
          &nbsp;&nbsp;　前職の月給以上を保証
          <br />
          &nbsp;&nbsp;〇パートタイマー：時給￥1,500円以上（経験年数・実績により要相談）
          <br />
          <br />
          &nbsp;&nbsp;※昇給年1回
          <br />
          &nbsp;&nbsp;※賞与　初年度年1回　それ以降　年2回（病院の業績による）
        </p>
        <p>
          <ul>
            <li>・社保完備</li>
            <li>・徒歩、自転車通勤可能な距離の場合　住宅手当支給</li>
            <li>・交通費支給（月額上限15,000円）</li>
            <li>・経験豊富なスタッフによる研修あり</li>
            <li>・社員割引あり　自分のペットの診療費割引</li>
            <li>・自転車・バイク通勤可</li>
            <li>・転勤なし</li>
            <li>・制服貸与</li>
            <li>・セミナー、学会参加補助</li>
          </ul>
        </p>
        <h3>【エントリー方法】</h3>
        <p>
          &nbsp;&nbsp;エントリー専用メールアドレスに下記内容をご記載の上、送信してください。後日こちらからご連絡させていただきます。
        </p>
        <p>
          <ul>
            <li>・氏名</li>
            <li>・年齢　性別</li>
            <li>・動物看護師としての経験年数</li>
            <li>・これまでの職歴</li>
            <li>・電話番号</li>
          </ul>
        </p>
        <p>
          &nbsp;&nbsp;エントリー専用メールアドレス
          <br />
          &nbsp;&nbsp;<span css={emphasis}>acct.cc.recruit@gmail.com</span>
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

const pageBaseStyle = css`
  padding: 1rem 4rem;

  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.3rem;
  }

  section {
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
    margin-top: 2rem;
  }

  h2 {
    border-left: solid 0.8rem #80d8ff;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    clear: both;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  ul li {
    padding-left: 1em;
    text-indent: -1em;
  }
`;

const skewBorder = css`
  width: 50%;
  height: 0.5rem;
  margin: 1rem auto 0;
  background-color: #80d8ff;
  background-image: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 7px,
    transparent 0,
    transparent 14px
  );

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const emphasis = css`
  font-weight: bold;
  color: #e66a6a;
`;
